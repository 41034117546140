const data = [
  {
    id: 1,
    text: "inicio",
    url: "/",
  },
  {
    id: 2,
    text: "sobre mi",
    url: "/about/",
  },
  {
    id: 3,
    text: "portfolio",
    url: "/portfolio/",
  },
  {
    id: 4,
    text: "blog",
    url: "/blog/",
  },
  {
    id: 5,
    text: "contacto",
    url: "/contact/",
  },
]

export default data
