// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogs-strapi-blogs-title-js": () => import("./../../../src/pages/blogs/{StrapiBlogs.title}.js" /* webpackChunkName: "component---src-pages-blogs-strapi-blogs-title-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-bsale-js": () => import("./../../../src/pages/portfolio/bsale.js" /* webpackChunkName: "component---src-pages-portfolio-bsale-js" */),
  "component---src-pages-portfolio-chocograin-js": () => import("./../../../src/pages/portfolio/chocograin.js" /* webpackChunkName: "component---src-pages-portfolio-chocograin-js" */),
  "component---src-pages-portfolio-cool-accesorios-js": () => import("./../../../src/pages/portfolio/cool-accesorios.js" /* webpackChunkName: "component---src-pages-portfolio-cool-accesorios-js" */),
  "component---src-pages-portfolio-ctm-js": () => import("./../../../src/pages/portfolio/ctm.js" /* webpackChunkName: "component---src-pages-portfolio-ctm-js" */),
  "component---src-pages-portfolio-d-compus-js": () => import("./../../../src/pages/portfolio/d-compus.js" /* webpackChunkName: "component---src-pages-portfolio-d-compus-js" */),
  "component---src-pages-portfolio-eco-cava-js": () => import("./../../../src/pages/portfolio/eco-cava.js" /* webpackChunkName: "component---src-pages-portfolio-eco-cava-js" */),
  "component---src-pages-portfolio-futbol-club-barcelona-js": () => import("./../../../src/pages/portfolio/futbol-club-barcelona.js" /* webpackChunkName: "component---src-pages-portfolio-futbol-club-barcelona-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-lit-killah-js": () => import("./../../../src/pages/portfolio/lit-killah.js" /* webpackChunkName: "component---src-pages-portfolio-lit-killah-js" */),
  "component---src-pages-portfolio-promon-js": () => import("./../../../src/pages/portfolio/promon.js" /* webpackChunkName: "component---src-pages-portfolio-promon-js" */),
  "component---src-pages-portfolio-tu-plan-js": () => import("./../../../src/pages/portfolio/tu-plan.js" /* webpackChunkName: "component---src-pages-portfolio-tu-plan-js" */),
  "component---src-pages-portfolio-zerinthia-js": () => import("./../../../src/pages/portfolio/zerinthia.js" /* webpackChunkName: "component---src-pages-portfolio-zerinthia-js" */)
}

