import React from "react"
import {
  FaWhatsapp,
  FaLinkedin,
  FaInstagram,
  FaBehanceSquare,
} from "react-icons/fa"

const data = [
  {
    id: 1,
    icon: <FaWhatsapp className="social-icon"></FaWhatsapp>,
    url: "tel:+59894661419",
  },
  {
    id: 2,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com/in/madebyjona/",
  },
  {
    id: 3,
    icon: <FaBehanceSquare className="social-icon"></FaBehanceSquare>,
    url: "https://www.behance.net/madebyjona",
  },
  {
    id: 4,
    icon: <FaInstagram className="social-icon"></FaInstagram>,
    url: "https://www.instagram.com/made.by.jona",
  },

]

export default data
